import React from "react"
import { useOrganizationDashboard } from "../../hooks"
import { MenuItemConfiguration, SubmenuItemConfiguration } from "../left-navigation/menu-items"
import { Icons, Icon, Loop45Icons } from "../../ui"
import { ProgramWizardStep } from "pitch45-common/models/program"
import { useStores } from "pitch45-common/stores/root-store-context"
import { SessionStore } from "pitch45-common/stores/session-store"
import { Permission } from "pitch45-common/models/session"

export const dashboardNavigator = {
  getBaseRoute: (organizationId: string) => `/dashboard/${organizationId}`,
  getOrganizationDashboardRoute: (organizationId: string) => `/dashboard/${organizationId}`,
  getOrganizationProfileRoute: (organizationId: string) => `/dashboard/${organizationId}/profile`,
  getOrganizationProfileBillingRoute: (organizationId: string) =>
    `/dashboard/${organizationId}/profile/billing`,
  getOrganizationMembersRoute: (organizationId: string) => `/dashboard/${organizationId}/members`,
  getOrganizationProgramsRoute: (organizationId: string) => `/dashboard/${organizationId}/programs`,
  getOrganizationEditRoute: (organizationId: string) => `/dashboard/${organizationId}/edit`,
  getCreateGroupRoute: (organizationId: string) => `/dashboard/${organizationId}/group/create`,
  getGroupOverviewRoute: (organizationId: string, groupId: string) =>
    `/dashboard/${organizationId}/group/${groupId}`,
  getGroupProfileRoute: (organizationId: string, groupId: string) =>
    `/dashboard/${organizationId}/group/${groupId}/profile`,
  getGroupMembersRoute: (organizationId: string, groupId: string) =>
    `/dashboard/${organizationId}/group/${groupId}/members`,
  getGroupProgramsRoute: (organizationId: string, groupId: string) =>
    `/dashboard/${organizationId}/group/${groupId}/programs`,
  getGroupVideosRoute: (organizationId: string, groupId: string) =>
    `/dashboard/${organizationId}/group/${groupId}/videos`,
  getGroupPlaylistsRoute: (organizationId: string, groupId: string) =>
    `/dashboard/${organizationId}/group/${groupId}/playlists`,
  getCreatePlaylistRoute: (organizationId: string, groupId: string) =>
    `/dashboard/${organizationId}/group/${groupId}/playlists/create`,
  getCreateProgramRoute: (organizationId: string, groupId: string, step: ProgramWizardStep) =>
    `/dashboard/${organizationId}/group/${groupId}/programs/create/${step}`,
  getGroupVideoDetailRoute: (organizationId: string, groupId: string, pitchId: string) =>
    `/dashboard/${organizationId}/group/${groupId}/videos/${pitchId}`,
  getGroupPlaylistDetailRoute: (organizationId: string, groupId: string, playlistId: string) =>
    `/dashboard/${organizationId}/group/${groupId}/playlists/${playlistId}`,
  getGroupEditPlaylistRoute: (organizationId: string, groupId: string, playlistId: string) =>
    `/dashboard/${organizationId}/group/${groupId}/playlists/${playlistId}/edit`,
  getGroupProgramDetailRoute: (organizationId: string, groupId: string, programId: string) =>
    `/dashboard/${organizationId}/group/${groupId}/programs/${programId}`,
  getUserManageOverviewRoute: () => `/account/overview`,
  getUserManageAccountRoute: () => `/account/manage`,
  getUserManagePasswordRoute: () => `/account/password`,
  getLogoutRoute: () => `/logout`,
}

export const useOrganizationMenu = (
  organizationId?: string,
): { menuItems: MenuItemConfiguration[] } => {
  const { sessionStore } = useStores()
  const { organization } = useOrganizationDashboard()
  if (!organizationId) {
    return { menuItems: [] }
  }

  const showBillingTab = sessionStore.hasPermission(organization?.id, Permission.ViewBilling)
  const showMembersTab = sessionStore.hasPermission(organization?.id, Permission.ManageUsers)
  const showOverviewTab = sessionStore.hasPermission(organization?.id, Permission.ViewGroups)

  const iconStyles = { marginTop: "-0.25em", marginLeft: "2px" }

  const menuItems: MenuItemConfiguration[] = []

  if (showOverviewTab) {
    menuItems.push({
      id: `${organizationId}-groups`,
      label: "Overview",
      icon: <Icon icon={Loop45Icons.Home} size="medium" css={iconStyles} />,
      redirectUri: dashboardNavigator.getOrganizationDashboardRoute(organizationId),
    })
  }

  if (showBillingTab) {
    menuItems.push({
      id: `${organizationId}-billing`,
      label: "Billing",
      redirectUri: dashboardNavigator.getOrganizationProfileBillingRoute(organizationId),
      icon: <Icon icon={Loop45Icons.Billing} size="medium" css={iconStyles} />,
    })
  }

  if (showMembersTab) {
    menuItems.push({
      id: `${organizationId}-members`,
      label: "Members",
      icon: <Icon icon={Loop45Icons.Members} size="medium" css={iconStyles} />,
      redirectUri: dashboardNavigator.getOrganizationMembersRoute(organizationId),
    })
  }

  return {
    menuItems,
  }
}

export const getGroupMenuItems = (
  sessionStore: SessionStore,
  organizationId?: string,
  groupId?: string,
): SubmenuItemConfiguration[] => {
  if (!organizationId || !groupId) return []
  const showContentTabs =
    sessionStore.hasPermission(groupId, Permission.ManageGroup) ||
    sessionStore.hasPermission(organizationId, Permission.ManageGroups)
  return [
    ...(showContentTabs
      ? [
          {
            id: `group-${groupId}-videos`,
            label: "Videos",
            redirectUri: dashboardNavigator.getGroupVideosRoute(organizationId, groupId),
          },
          {
            id: `group-${groupId}-playlists`,
            label: "Playlists",
            redirectUri: dashboardNavigator.getGroupPlaylistsRoute(organizationId, groupId),
          },
        ]
      : []),
    {
      id: `group-${groupId}-members`,
      label: "Members",
      redirectUri: dashboardNavigator.getGroupMembersRoute(organizationId, groupId),
    },
    {
      id: `group-${groupId}-profile`,
      label: "Profile",
      redirectUri: dashboardNavigator.getGroupProfileRoute(organizationId, groupId),
    },
  ]
}

export const getUserMenuItems = (): MenuItemConfiguration[] => {
  return [
    {
      id: "overview",
      label: "Overview",
      icon: <Icon icon={Icons.Dashboard} size="medium" />,
      redirectUri: dashboardNavigator.getUserManageOverviewRoute(),
    },
    {
      id: "account",
      label: "Profile",
      icon: <Icon icon={Icons.Face} size="medium" />,
      redirectUri: dashboardNavigator.getUserManageAccountRoute(),
    },
    {
      id: "password",
      label: "Password",
      icon: <Icon icon={Icons.Password} size="medium" />,
      redirectUri: dashboardNavigator.getUserManagePasswordRoute(),
    },
  ].filter((x) => x)
}
