import React from "react"
import { CSSObject, useTheme } from "@emotion/react"
import { AppTheme } from "pitch45-common/theme/app-theme.types"
import { mediaQueries } from "pitch45-common/theme/layout"
import { Text } from "../../ui"
import { MAX_CONTENT_WIDTH } from "../../theme/box"
import { textPresets } from "../../ui/text/text.presets"
import { semanticSpacing } from "../../theme/spacing"

interface FooterProps {
  containerStyle?: CSSObject
}

export const FOOTER_DESKTOP_HEIGHT = "50px"

function getStyles(theme: AppTheme) {
  const styles = {
    CONTAINER: {
      backgroundColor: "transparent",
      position: "fixed",
      bottom: 0,
      width: "100%",
      height: FOOTER_DESKTOP_HEIGHT,
      [mediaQueries.TABLET]: {
        display: "none",
      },
    },
    CONTENT: {
      maxWidth: MAX_CONTENT_WIDTH,
      height: FOOTER_DESKTOP_HEIGHT,
      margin: "0 auto",
      padding: semanticSpacing.horizontal.narrow,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    COPYRIGHT: {
      color: theme.colors.secondaryText,
    },
  }
  return styles as { [key in keyof typeof styles]: CSSObject }
}

export function Footer({ containerStyle }: FooterProps) {
  const theme = useTheme() as AppTheme
  const styles = getStyles(theme)
  return (
    <footer css={[styles.CONTAINER, containerStyle]}>
      <div css={styles.CONTENT}>
        <Text preset={textPresets.tiny} css={styles.COPYRIGHT}>
          © {new Date().getFullYear()} Loop :45 Video Communications, Inc |{" "}
          <a href="mailto:support@loop45.com" target="_blank" rel="noreferrer">
            Contact
          </a>
        </Text>
      </div>
    </footer>
  )
}

export default Footer
