import React from "react"
import { CSSObject, useTheme } from "@emotion/react"
import { Text } from "../text/text"
import { AppTheme } from "pitch45-common/theme/app-theme.types"
import { textPresets } from "../text/text.presets"
import { unitSpacing } from "../../theme/spacing"
import { Dialog } from "@mui/material"
import Button from "../button/button"
import AsyncButton from "../button/async-button"

interface ConfirmationModalProps {
  open: boolean
  message?: string
  loading?: boolean
  confirmationText?: string
  declineText?: string
  onConfirm: () => Promise<any>
  onDecline?: () => void
}

function getStyles(theme: AppTheme) {
  const styles = {
    CONTAINER: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    MESSAGE_CONTAINER: {
      minWidth: "400px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: unitSpacing.double,
      borderTop: `2px solid ${theme.colors.primary}`,
      background: theme.colors.background,
    },
    CTA_CONTAINER: {
      padding: unitSpacing.unit,
      background: theme.colors.background,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-around",
    },
    CTA_BUTTON: {
      margin: `0 ${unitSpacing.half}`,
    },
    BACKDROP: {
      backgroundColor: theme.colors.halfTransparentBackground,
      position: "fixed",
      height: "100vh",
      width: "100vw",
    },
  }
  return styles as { [key in keyof typeof styles]: CSSObject }
}

const Backdrop = () => {
  const theme = useTheme() as AppTheme
  const styles = getStyles(theme)
  return <div css={styles.BACKDROP} />
}

export const ConfirmationModal = (props: ConfirmationModalProps) => {
  const {
    open,
    message,
    loading,
    confirmationText = "Confirm",
    declineText = "Cancel",
    onConfirm,
    onDecline,
  } = props
  const theme = useTheme() as AppTheme
  const styles = getStyles(theme)

  return (
    <Dialog css={styles.CONTAINER} open={open} slots={{ backdrop: Backdrop }}>
      <div css={styles.MESSAGE_CONTAINER}>
        <Text preset={textPresets.large}>{message}</Text>
      </div>
      <div css={styles.CTA_CONTAINER}>
        {onDecline && (
          <Button
            css={styles.CTA_BUTTON}
            preset="clear"
            label={declineText}
            disabled={loading}
            onClick={onDecline}
          />
        )}
        <AsyncButton
          css={styles.CTA_BUTTON}
          preset="primary"
          loading={Boolean(loading)}
          label={confirmationText}
          onClick={onConfirm}
        />
      </div>
    </Dialog>
  )
}

export default ConfirmationModal
