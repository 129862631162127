import { useQueryClient } from "@tanstack/react-query"
import { useRouter } from "next/router"
import { useStores } from "pitch45-common/stores/root-store-context"
import { dashboardNavigator } from "../components/dashboard-layout/navigationConfig"
import { Permission } from "pitch45-common/models/session"
import logger from "pitch45-common/logging/logger"
import { fetchGroupsForAdminQueryKey } from "pitch45-common/utils"

export function usePostLoginRedirect(returnUrl?: string) {
  const { sessionStore, paymentsStore, sessionMembershipStore, environment, groupStore } =
    useStores()

  const getDefaultOrgId = async () => {
    let orgId = (await environment.storage.loadString("last-org-id")) ?? ""
    if (!sessionMembershipStore.sessionAdminOrganizations.some((o) => o.id === orgId)) {
      orgId = sessionMembershipStore.sessionAdminOrganizations[0]?.id
    }
    return orgId
  }

  const queryClient = useQueryClient()
  const router = useRouter()

  return async function postLoginRedirect() {
    queryClient.clear()
    if (returnUrl) {
      router.push(returnUrl)
      return
    }

    if (!sessionStore.hasAccessToken) {
      await sessionStore.oauthAuthorize()
    }

    try {
      await Promise.all([
        queryClient.fetchQuery(["fetchLoggedInSession"], () => sessionStore.fetchLoggedInSession()),
        queryClient.fetchQuery(["fetchSessionOrganizations"], () =>
          sessionMembershipStore.fetchSessionOrganizations(),
        ),
        queryClient.fetchQuery(["fetchCurrentUser"], () => sessionStore.fetchCurrentUser()),
      ])

      const orgId = await getDefaultOrgId()
      if (orgId) {
        const [hasSubscription, dashboardGroups] = await Promise.all([
          queryClient.fetchQuery(["fetchHasValidEntitySubscription", orgId], () =>
            paymentsStore.fetchHasValidEntitySubscription(orgId),
          ),
          queryClient.fetchQuery(fetchGroupsForAdminQueryKey(orgId), () =>
            groupStore.fetchGroupsForAdmin(orgId),
          ),
        ])

        if (!hasSubscription && sessionStore.hasPermission(orgId, Permission.ViewBilling)) {
          router.push(dashboardNavigator.getOrganizationProfileBillingRoute(orgId))
          return
        }

        if (
          dashboardGroups?.length === 0 &&
          sessionStore.hasPermission(orgId, Permission.ManageGroups)
        ) {
          router.push(dashboardNavigator.getCreateGroupRoute(orgId))
          return
        }

        router.push(`/dashboard/${orgId}`)
        return
      }
    } catch (e) {
      logger.log(e)
    }

    router.push(`/dashboard`)
  }
}
