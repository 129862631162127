import { useEffect, useState } from "react"
import { useRouter } from "next/router"

// https://github.com/vercel/next.js/discussions/16337
export function useHistory() {
  const router = useRouter()
  const [history, setHistory] = useState<string[]>([])

  useEffect(() => {
    const handleRouteChange = (url: any, { shallow }: any) => {
      if (!shallow) {
        setHistory((prevState) => [...prevState, url])
      }
    }

    router.beforePopState(() => {
      setHistory((prevState) => prevState.slice(0, -2))
      return true
    })

    router.events.on("routeChangeStart", handleRouteChange)

    return () => {
      router.events.off("routeChangeStart", handleRouteChange)
    }
  })

  // check if history contains a path with the give prefix
  const findPrefixInHistory = (prefix: string): string | undefined => {
    if (history.length < 2) {
      return undefined
    }
    for (let i = history.length - 2; i >= 0; i--) {
      if (history[i].startsWith(prefix)) {
        return history[i]
      }
    }
    return undefined
  }

  return {
    router,
    history,
    canGoBack: () => history.length > 1,
    goBack: () => router.back(),
    findPrefixInHistory,
  }
}
