import { isArray } from "lodash-es"
import { useRouter } from "next/router"
import { useStores } from "pitch45-common/stores/root-store-context"
import { Organization } from "pitch45-common/models/organization"
import { Group } from "pitch45-common/models/group"

interface OrganizationDashboardHook {
  organization?: Organization
  organizationId?: string
  group?: Group
  groupId?: string
}

export const useOrganizationDashboard = (): OrganizationDashboardHook => {
  const router = useRouter()
  const { sessionMembershipStore, groupStore } = useStores()

  if (isArray(router.query.organizationId)) {
    throw new Error("Cannot specify multiple org ids in url")
  }
  if (isArray(router.query.groupId)) {
    throw new Error("Cannot specify multiple group ids in url")
  }

  const { sessionOrganizations } = sessionMembershipStore
  const organization = sessionOrganizations.find((o) => o?.id === router.query.organizationId)
  const organizationId = organization?.id

  const groupId = router.query.groupId as string
  const group = router.query.groupId ? groupStore.groups.get(groupId) : undefined

  if (group && group.organizationId !== organizationId) {
    throw new Error("Group does not belong to organization")
  }

  return {
    organizationId,
    organization,
    group,
    groupId,
  }
}
