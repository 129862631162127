import React, { ReactNode } from "react"
import { CSSObject, ThemeProvider } from "@emotion/react"
import { AppTheme } from "pitch45-common/theme/app-theme.types"
import Footer from "../footer/footer"
import { observer } from "mobx-react-lite"
import { theme } from "pitch45-common/theme/theme"
import { getMergedTheme } from "../../theme/muiTheme"
import Head from "next/head"

interface MainLayoutProps {
  children: ReactNode
  contentTheme?: AppTheme
}

function getStyles(theme: AppTheme) {
  const styles = {
    PAGE: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      height: "100%",
      width: "100%",
      minHeight: "100vh",
      minWidth: "100vw",
    },
    MAIN: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      lineHeight: 1.4,
      background: theme.colors.background,
      height: "100%",
      minHeight: "600px",
    },
    MAIN_CONTENT: {
      width: "100%",
      margin: "0 auto",
      height: "100%",
    },
  }
  return styles as { [key in keyof typeof styles]: CSSObject }
}

const MainLayout = observer(function MainLayout({
  children,
  contentTheme = theme.pitch45Dark,
}: MainLayoutProps) {
  const styles = getStyles(contentTheme)
  const mergedTheme = getMergedTheme(contentTheme)
  return (
    <>
      <Head>
        <meta name="og:site_name" content="Loop: 45" />
        <meta name="og:image" content="/imgs/og/logo.png" />
      </Head>
      <div css={styles.PAGE}>
        <ThemeProvider theme={mergedTheme}>
          <main css={styles.MAIN}>
            <div css={styles.MAIN_CONTENT}>{children}</div>
          </main>
        </ThemeProvider>
        <Footer />
      </div>
    </>
  )
})

export default MainLayout
